import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO, Listen } from "components";
import {
	Banner,
	PreviousEpisode,
	NextEpisode,
	PlayEpisode,
} from "components/episode";
import {
	LatestEpisodesList,
	HostsList,
	ExtendedDescription,
	Gallery,
	RelatedContent,
} from "components/podcast";
import { Section } from "components/layout";
import Image from "gatsby-image";
import Helmet from "react-helmet";
import { getGatsbyImage } from "helpers";
import _ from "lodash";
import useMenu from "state/useMenu";
/* Episode Template
============================================================================= */

const EpisodeTemplate = ({ pageContext, location }) => {
	// Config
	const { podcast, episode, episodes } = pageContext;
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// Get Next Episode
	const getNextEpisode = () => {
		const currentIndex = _.findIndex(episodes, (el) => {
			return el.name === episode.name;
		});
		if (currentIndex === 0) {
			return null;
		}
		return (
			<NextEpisode
				episode={episodes[currentIndex - 1]}
				episodeNumber={undefined}
			/>
		);
	};

	// Get Previous Episode
	const getPreviousEpisode = () => {
		const currentIndex = _.findIndex(episodes, (el) => {
			return el.name === episode.name;
		});
		if (currentIndex + 1 === episodes.length) {
			return null;
		}
		return (
			<PreviousEpisode
				episode={episodes[currentIndex + 1]}
				episodeNumber={undefined}
			/>
		);
	};

	// Render
	return (
		<>
			<Helmet title={`${podcast[0].name}: ${episode.name}`} lang="en">
				<meta name="image" content={podcast[0].image.image.asset.url} />
				<meta
					property="og:title"
					content={`${podcast[0].name}: ${episode.name}`}
				/>
				<meta
					property="og:image"
					content={podcast[0].image.image.asset.url}
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:image"
					content={podcast[0].image.image.asset.url}
				/>
				<meta property="og:url" content={location.href} />
				<meta property="og:description" content={episode.description} />
				<meta property="og:type" content="article" />
			</Helmet>
			<Banner
				episode={episode}
				podcast={podcast[0]}
				location={location}
			/>

			<section
				role="region"
				aria-label="Play Episode"
				className={`w-full horizontal-padding relative pt-2`}
			>
				<PlayEpisode
					episode={episode}
					location={location}
					noTranscript={episode._rawTranscript === null}
				/>
			</section>

			<ExtendedDescription
				description={episode._rawExtendedDescription}
			/>
			<Gallery galleryItems={episode.gallery} />
			{getNextEpisode()}
			{getPreviousEpisode()}
			<HostsList hosts={podcast[0].hosts} />
			<RelatedContent
				podcasts={podcast[0].related_podcasts}
				articles={podcast[0].related_articles}
			/>
		</>
	);
};

/* Export
============================================================================= */

export default EpisodeTemplate;
